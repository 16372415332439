class SystemIconHelper {

  //Returns a font awesome icon for a specified activity type
  static activityType(activityType, recordingOverride = false) {
    let icon = "";

    switch(activityType.id) {
      case 1:
        icon = "fas fa-pencil-alt"
        break;
      case 2:
        icon = "far fa-calendar-alt"
        break;
      case 3:
        icon = "fas fa-comments"
        break;
      case 4:
        icon = "far fa-check-square"
        break;
      case 5:
        icon = "fas fa-camera"
        break;
      case 6:
        icon = "fas fa-lightbulb"
        break;
      case 7:
        if (recordingOverride) {
          icon = "fas fa-chalkboard-teacher"
          break;
        } else {
          icon = "fas fa-external-link-alt"
          break; 
        }
      case 8:
      case 10:
        icon = "fas fa-map-pin"
        break;
      case 9:
        icon = "fas fa-chart-pie"
        break;
    }

    return icon;
  }
}

export default SystemIconHelper;